import Image from 'next/image';

import { featureApps, IFeatureApp } from '@containers/home/components/feature-app/_data';

interface IFeatureAppItems {
  selected: IFeatureApp;
  setSelected: any;
}

const FeatureAppItems = ({ selected, setSelected }: IFeatureAppItems) => {
  const arrowItem = (
    <div className="hidden md:block w-[28px] h-[28px] ml-[20px]">
      <i>arrow_forward_ios</i>
    </div>
  );

  return (
    <>
      <ul className="feature-app--content-list">
        {featureApps.map((featureApp: IFeatureApp, i: number) => (
          <li
            className="feature-app--content-item"
            data-unq="landingpage-button-onwork"
            key={i}
            onClick={() => setSelected(featureApp)}>
            <div className="feature-app--content-item-img">
              <Image src={featureApp.icon} alt={featureApp.title} width={64} height={64} />
            </div>
            <div className="feature-app--content-item-card">
              <div className="feature-app--content-item-ccard">
                <span className="feature-app--content-item-tcard">{featureApp.title}</span>
                <span className="feature-app--content-item-tdcard">{featureApp.description}</span>
              </div>
            </div>
            {selected.id == featureApp.id ? arrowItem : null}
          </li>
        ))}
      </ul>
    </>
  );
};

export default FeatureAppItems;
